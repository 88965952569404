.container{
    width: 100%;
    /* height: fit-content; */
    height: 100%;
    min-height: 100vh;
    color: white;
    display: flex;
    justify-content: center;
    font-family: 'DM Sans';
    font-style: normal;
    overflow-x: hidden;
}
.contained{
    /* border: 2px solid red; */
    width: 90%;
    margin: 8% auto 0;
    padding: 0px auto;
}
.contained h1{
    font-weight: 500;
    font-size: 24px;
    color: #35ABAF;
    text-align: center;
}
.contained h2{
    text-align: center;
}
.contained p{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
}

.footer{
  margin-top: 30px;
}
.mainContainer{
   
    padding-right: 200px;
    width:80%;
    
}

