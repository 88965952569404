.main{
  background-image: url("../../assets/png/bgImg2.png");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}

.container {
  width: 100%;
  /* height: 100vh; */
  /* height: fitcontent; */
  display: flex;
  color: white;
  justify-content: center;
  font-family: "DM Sans";
  font-style: normal;
}

.contained {
  width: 95%;
  margin-top: 132px;
  height: auto;
  min-height: 100vh;
  display: flex;
}

.left {
  height: 100%;
  width: 60%;
}

.image_box {
  height: 673px;
  border: 1px solid #999999;
  border-radius: 12px;
  overflow: hidden;
}

.main_img {
  width: 100%;
  height: 600px;
  position: relative;
}
.main_img img {
  width: 100%;
  height: 100%;
}
.likes_bx {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 12px;
  gap: 8px;
  position: absolute;
  width: 79px;
  height: 34px;
  top: 20px;
  right: 5%;
  cursor: pointer;
  background: #484848;
  border-radius: 8px;
}

.image_box > div:nth-child(2) {
  width: 100%;
  height: 73px;
  display: flex;
  justify-content: space-between;
}

.leftBelow {
  width: 20%;
  height: 100%;
  padding: 10px;
  padding-left: 20px;
}

.leftBelow p {
  font-weight: 700;
  font-size: 16px;
  line-height: 10px;
  margin: 0;
}

.leftBelow span {
  font-weight: 400;
  font-size: 12px;
  text-align: start;
  cursor: pointer;
  /* margin: 0; */
  /* margin: auto; */
  display: flex;
  margin-top: 5px;
  /* margin: auto; */
  gap: 10px;
  align-items:center ;
}
.img_div{
  width: 30px;
  margin: 0;
  height: 30px;
  border-radius: 100%;
  overflow: hidden;
}
.img_div img{
  width: 100%;
  height: 100%;
}
.rightBelow {
  /* border: 2px solid red; */
  width: 50%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  /* background: white; */
}
.rightBelow a{
  color: white;
}

.status_box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: fit-content;
  height: fit-content;
  box-shadow: 24;
  padding: 30px;
  background: #484848;
  border: 1px solid #999999;
  border-radius: 12px;
  color: white;
}
.status_box h1 {
  font-weight: 500;
  font-size: 36px;
  text-align: center;
  color: #ffffff;
  margin: 0;
}
.status_box p {
  font-weight: 500;
  font-size: 20px;
  text-align: center;
  color: #ffffff;
}
.btn_status {
  width: 80%;
  height: 44px;
  background: #35abaf;
  border-radius: 30px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
}

.rightBelow div {
  width: 40px;
  height: 40px;
  background: #484848;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.right {
  padding-left: 2%;
  /* margin-left: 5%; */
  width: 40%;
}

.right h1 {
  font-weight: 500;
  font-size: 24px;
}

.right p {
  color: #999999;
  font-weight: 400;
  font-size: 16px;
}

.start {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.start span {
  height: 30px;
  width: 120px;
}
.start select {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: transparent;
  color: white;
  font-weight: 700;
  font-size: 16px;
}
.start select option {
  background-color: transparent !important;
  color: black;
  padding: 5px;
}
.start div {
  width: 100px;
  height: 36px;
  background: #35abaf;
  border-radius: 30px;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.start select {
  /* border: 2px solid red; */
  border: none;
  cursor: pointer;
}

.text {
  width: 70%;
}
.text a{
  color: #35abaf;
  text-decoration: none;
}
.text a:hover{
  text-decoration: underline;
}

.button {
  display: flex;
  gap: 20%;
  /* border: 2px solid red; */
}
.active {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  width: 240px;
  height: 44px;
  background: #35abaf;
  border-radius: 30px;
  margin-top: 36px;
  font-weight: 700;
  font-size: 16px;
  cursor: pointer;
}

.button div {
  width: 35%;
  height: 52px;
  background: #35abaf;
  border-radius: 8px;
  cursor: pointer;
  color: white;
  font-weight: 700;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.left_table {
  margin-top: 2rem;
  width: 90%;
  margin-left: 5%;
  height: auto;
  border: 1px solid #999999;
  border-radius: 12px;
  overflow: hidden;
  height: 500px;
  overflow: scroll;
}
.left_table::-webkit-scrollbar {
  display: none;
}
.table::-webkit-scrollbar {
  display: none;
}
.button div:nth-child(2) {
  background: linear-gradient(0deg, rgba(5, 5, 5, 0.1), rgba(5, 5, 5, 0.1)),
    linear-gradient(
      95.57deg,
      rgba(53, 171, 175, 0.26) 0.42%,
      rgba(5, 5, 5, 0.4) 100%
    );
  border: 2px solid #35abaf;
}

.table {
  margin-top: 1rem;
  width: 90%;
  height: 584px;
  border-radius: 12px;
  overflow: scroll;
  border: 1px solid #818181;
}

.customers {
  font-family: "DM Sans";
  font-style: normal;
  border-collapse: collapse;
  width: 100%;
  /* height: 100%; */
  /* height: 20px; */
  /* height: 100%; */
}
/* .customers tr{
  border: 2px solid red;
  height: 20%;
} */

.customers td,
.customers th {
  /* border: 1px solid #ddd; */
  padding: 8px;
}
.customers tr:nth-child(1) {
  border-bottom: 1px solid #818181;
}
.customers tr {
  background-color: #232323;
}

.customers tr:hover {
  background-color: transparent;
}

.customers th {
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 12px;
  text-align: center;
  text-align: left;
  background-color: black;
  color: white;
}
.below {
  width: 100%;
  font-family: "DM Sans";
  font-style: normal;
}
.below h1 {
  width: 100%;
  display: flex;
  text-align: center;
  justify-content: center;
  font-weight: 700;
}
.nfts {
  /* width: 100%; */
  display: flex;
  flex-wrap: wrap;
  
  justify-content: center;
  /* grid-template-columns: repeat(4,auto); */
  gap: 2rem 8%;
  margin-top: 2rem;
}
.nft {
}
.price_box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 30%;
  box-shadow: 24;
  padding: 20px;
  border-radius: 10px;
  background: linear-gradient(
    135deg,
    rgba(0, 0, 0, 1) 2.88%,
    rgba(74, 174, 177, 0.8) 220.14%
  );
  color: white;
  /* border: 1px solid white; */
}
.price_box Button {
  width: 40%;
  background: #35abaf;
}
.arrowofnft{
  width: 95%;
  margin: 2.5%;
  top: 45%;
  display: flex;
  align-items:center;
  justify-content: space-between;
  position: absolute;
}
@media only screen and (max-width: 600px) {
  .contained {
    display: block;
  }
  .left {
    width: 100%;
    height: fit-content;
  }
  .right {
    width: 100%;
    margin-top: 2rem;
  }
  .text {
    width: 100%;
  }
  .table {
    width: 100%;
  }
  .customers {
    font-family: "DM Sans";
    font-style: normal;
    border-collapse: collapse;
    width: 100%;
    height: 100%;
    overflow-x: scroll;
  }
}
