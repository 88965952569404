.MuiDataGrid-row.bidTable {
    background: #232323 !important;
}

.MuiDataGrid-row.Mui-odd {
    background-color: #C4C4C4 !important;
    color: #000;
}

.MuiDataGrid-row.Mui-odd {
    background-color: #C4C4C4 !important;
    color: #000;
}

.MuiDropzoneArea-root {
    background-color: #484848 !important;
    border: none !important;
}

.MuiDropzoneArea-text {
    color: #fff
}

.MuiDropzoneArea-icon {
    color: #fff !important;
}