.gradBtn {
    background: linear-gradient(95.57deg, rgba(53, 171, 175, 0.26) 0.42%, rgba(5, 5, 5, 0.4) 100%) !important;
}

.gradBtn2 {
    background: linear-gradient(0deg, rgba(5, 5, 5, 0.1), rgba(5, 5, 5, 0.1)), linear-gradient(95.57deg, rgba(53, 171, 175, 0.26) 0.42%, rgba(5, 5, 5, 0.4) 100%);
    border: 2px solid #35ABAF !important;
}

.roundBtn {
    background: #35ABAF !important;
    border-radius: 20px !important;
}

.roundBtnInActive {
    border-radius: 20px !important;
    background: #484848 !important;
    border-radius: 20px !important;
    color: #35ABAF !important;
}

.likeBtn {
    z-index: 1 !important;
}