.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  padding-bottom:16px;
  flex: start;
  background: linear-gradient(to bottom, black 0%, #35ABAF 100%,#35ABAF);
  height:auto;
}


.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;

}

.contained {
  width: 100%;
}

.head {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-bottom: 16px;
  gap: 10px;
  margin-top: 80px;

}

.head div {
  cursor: pointer;

  /* padding: 8px 16px;  */
  border: 1px solid transparent;
  transition: all 0.3s ease;
}

/* .head div:hover {
  border: 1px solid #fff; 
} */

.head .active {
  color: black;
}

.blogs {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 16px;
  
}

.left {
  background: linear-gradient(145deg, #000, #222);
  padding: 16px;
  border-radius: 12px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  border: 1px solid transparent;
}

.left:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.4);
  border: 1px solid black;
}

.mainImg img {
  width: 100%;
  height: auto;
  border-radius: 8px;
}


.left h1 {
  font-size: 1.5rem;
  margin: 8px 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: white;
}

.description {
  margin-top: 8px;
  flex-grow: 1;
  font-size: 1rem;
  line-height: 1.5;
  max-height: 100px;
  overflow: auto;
  color: #fff;
}

/* Custom WebKit Scrollbar */
.description::-webkit-scrollbar {
  width: 2px;
  /* Very thin scrollbar */
}

.description::-webkit-scrollbar-thumb {
  background-color: white;
  /* Thumb (scroll handle) color */
  border-radius: 10px;
  /* Rounded corners for the thumb */
}

.description::-webkit-scrollbar-track {
  background-color: transparent;
  /* Transparent track */
}

.description::-webkit-scrollbar-button {
  display: none;
  /* Hide the scrollbar arrows */
}

/* Responsive Styles */
@media (max-width: 768px) {
  .left h1 {
    font-size: 1.25rem;
  }

  .left {
    height: auto;
  }
}

@media (max-width: 480px) {
  .left h1 {
    font-size: 1rem;
  }

  .blogs {
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
  }

  .left {
    padding: 12px;
  }
}

/* Additional Enhancements */
.left .icon {
  font-size: 24px;
  margin-bottom: 8px;
  color: #007bff;
}

.left h1 {
  font-weight: bold;
  margin: 8px 0;
}

.left .description {
  font-style: italic;
  color: #ccc;
}

.active {
  border-radius: 18px;
  text-align: center;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  background-color: #35ABAF;
}