.gradBox {
    padding: 12px !important;
    background: linear-gradient(95.57deg, rgba(53, 171, 175, 0.26) 0.42%, rgba(5, 5, 5, 0.4) 100%) !important;
    border-radius: 8px !important;
}

.CLBBox {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
    overflow: hidden; /* Ensures no overflow */
    position: relative; /* Helps control absolute positioning inside */
}

.singleCLBBox {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px !important;
}

.SingelCLBBox {
    background-color: #000 !important;
    color: #fff !important;
    border-radius: 10px !important;
    overflow: hidden !important;
}

.alertBox {
    padding: 12px !important;
    background: rgba(53, 171, 175, 0.87) 100% !important;
    border-radius: 8px !important;
}