.main {
    // background-color: #000;
    color: #fff;
    width: 75% !important;
    border-top-right-radius: 12px;
    border-bottom-right-radius:12px;
    background: #050505;
    box-shadow: 0px 8px 4px 0px rgba(0, 0, 0, 0.25), 8px 0px 4px 0px rgba(0, 0, 0, 0.25);


    .main_ {
        padding: 0px 24px 10px 24px;
        background: linear-gradient(95.57deg, rgba(53, 171, 175, 0.26) 0.42%, rgba(5, 5, 5, 0.4) 100%);
        border-top-right-radius: 12px;
        border-bottom-right-radius:12px;

        .img {
            width: 100%;
            height: inherit;
        }
    }
}


@media only screen and (max-width: 900px) {
    
    .main{
          max-height: 80vh;
          overflow: auto;
    }
  
  }