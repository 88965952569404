.main {
  background-image: url("../assets/png/bgImg2.png");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;

  .container {
    width: 100%;
    /* height: fit-content; */
    height: 100%;
    min-height: 100vh;
    color: white;
    display: flex;
    justify-content: center;
    font-family: "DM Sans";
    font-style: normal;
  }

  .clbBox {
    height: 100px;
  }
}

.passwordField {
  label {
    color: #fff !important;
  }
}

button[title="Sort"] {
  color: #ffffff !important;
}
