.link {
  text-decoration: none;
  color: #35abaf;
  cursor: pointer;
}
.selectedMenu {
  color: red !important;
  background-color: red !important;
}

.confirmeBox {
  background-color: #232323;
  color: #fff;
  border: 1px solid #fff;
  padding: 10px;
}

.modal {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.otpInputs {
  width: 50px !important;
  height: 50px !important;
  border-radius: 10px;
  border: none !important;
  margin: 0 30px;
}

// banner image 


.carouselContainer {
  overflow: hidden; // Prevent scrolling overflow
  position: relative; // Ensure proper positioning
}

.carouselImage {
  width: 100%; // Full width of the container
  height: auto; // Maintain aspect ratio automatically
  max-height: 580px; // Further increased maximum height for the images
  object-fit: cover; // Ensure the image covers the area without distortion
  display: block; // Ensure no gaps below images
  transition: transform 0.5s ease; // Smooth transition effect
  object-fit: cover;
}

// Centering the carousel
.carousel {
  display: flex; // Use Flexbox for better alignment
  align-items: center; // Center items vertically
  justify-content: center; // Center items horizontally
}

// Responsive adjustments
@media (max-width: 1200px) {
  .carouselImage {
    max-height: 300px; // Adjust height for smaller screens
  }
}

@media (max-width: 992px) {
  .carouselImage {
    max-height: 280px; // Further adjust height for medium screens
  }
}

@media (max-width: 768px) {
  .carouselImage {
    max-height: 250px; // Further adjust height for smaller devices
  }
}

@media (max-width: 576px) {
  .carouselImage {
    max-height: 230px; // Further adjust height for extra small devices
  }
}



//cart


