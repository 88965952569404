@mixin gradBox {
  background: linear-gradient(0deg, rgba(53, 171, 175, 0.2), rgba(53, 171, 175, 0.2)), linear-gradient(95.57deg, rgba(5, 5, 5, 0.3) 0.42%, rgba(53, 171, 175, 0.24) 53.27%) !important;
  border-radius: 10px !important;
  border: 0 !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 60px 20px;
  margin: 20px 0;

  .titleText {
    font-weight: 700 !important;
    font-size: 20px !important;
    color: #A8FF96 !important;
    text-align: center;
  }
  .titleText1 {
    font-weight: 700 !important;
    font-size: 20px !important;
    color: #87CEEB !important;
    text-align: center;
  }
  .titleText2 {
    font-weight: 700 !important;
    font-size: 20px !important;
    color: #FFFF00 !important;
    text-align: center;
  }

  .bodyText {
    font-weight: 700 !important;
    font-size: 18px !important;
    color: #D9D9D9 !important;
    text-align: center;
  }
}

.main {
  padding: 10px 0;

  .mainGradCard1 {
    position: relative;

    .gradCard1 {
      @include gradBox();
      height: 205px;
    }

    .backImg {
      position: absolute;
      top: 30%;
      left: -20%;
    }
  }

  .gradCard2 {
    @include gradBox();
    height: 150px;
  }

  .gradCard3 {
    @include gradBox();
    height: 150px;
  }

  .gradCard4 {
    @include gradBox();
    height: 205px;
  }
  
  .gradCard5 {
    @include gradBox();
    height: 205px;
  }

  .gradCard6 {
    @include gradBox();
    height: 150px;
  }
}


@media screen and (min-width: 900px) {
  .main {
    padding: 10px 200px !important;
  }
}