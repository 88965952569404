@import url("https://fonts.googleapis.com/css2?family=DM+Sans&family=Montserrat&display=swap");

.main {
  background-image: url("../assets/png/bgImg1.png");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;

  .others {
    background-image: url("../assets/png/bgImg2.png");
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
  }

  .startImgsMain {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    width: 60%;
    position: relative;

    & img:nth-child(1) {
      position: absolute;
      right: -5%;
      width: 10%;
    }

    & img:nth-child(2) {
      position: absolute;
      top: 10%;
      width: 15%;
    }
  }

  .Text3D {
    color: #35ABAF;
    font-weight: 500;
    text-shadow: 0px 6px 2px #82F9FD;
    letter-spacing: -0.03em !important;
  }

}

.leftcontainer {
  width: 80%;

  max-height: 50%;
  padding: 8rem 5rem;
  text-align: start;
}

.leftcontainer p {
  font-family: "DM Sans";
  font-weight: 400;
  color: white;
}

.head {
  width: 60%;
}

.head h2 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 64px;
  line-height: 80px;
}

.head>h1 {
  font-family: "Montserrat";
  font-size: 117px;
  line-height: 90%;
  color: #35abaf;
  width: 100%;
}

.bigbtn {
  display: flex;
  gap: 5%;
}

.bigbtn p {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  width: 25%;
  height: 52px;
  background: linear-gradient(0deg,
      rgba(53, 171, 175, 0.2),
      rgba(53, 171, 175, 0.2)),
    linear-gradient(95.57deg,
      rgba(5, 5, 5, 0.3) 0.42%,
      rgba(53, 171, 175, 0.24) 53.27%);
  border: 1px solid #35abaf;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  border-radius: 5%;
  cursor: pointer;
}

.bigbtn p:hover {
  box-shadow: yellow;
  transform: scale(1.05);
  transition: all 300ms;
  opacity: 2;
}

.details {
  width: 100%;
  margin-top: 2rem;
  height: 111px;
  background: linear-gradient(0deg, rgba(53, 171, 175, 0.2), rgba(53, 171, 175, 0.2)), linear-gradient(95.57deg, rgba(5, 5, 5, 0.3) 0.42%, rgba(53, 171, 175, 0.24) 53.27%);
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  padding-right: 2%;
  padding-left: 2%;
  border-radius: 12px;
}

.details h1 {
font-family: DM Sans;
font-size: 24px;
font-style: normal;
font-weight: 700;
line-height: normal;
letter-spacing: 0.48px;
}

.details p {
  color: #EDE4E4;
  text-align: center;
  font-family: DM Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 108.4%; /* 13.008px */
  letter-spacing: 0.24px;
}

.rightcontainer {
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.third-Dimension-model {
  width: 80%;
  height: 80%;
}

@media only screen and (max-width: 1400px) {
  .container {
    display: flex;
    height: fit-content;
  }

  .rightcontainer {
    height: 100vh;
    width: 100%;
  }

  .bigbtn p {
    width: 50%;
  }

  .leftcontainer {
    width: 100%;
    padding: 6rem 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .head h2 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
  }

  .head>h1 {
    font-family: "Montserrat";
    font-size: 50px;
    line-height: 40px;
    color: #35abaf;
  }

  .details {
    width: 100%;
  }

  .details h1 {
    font-size: 16px;
  }

  .details p {
    font-size: 8px;
  }

  .third-Dimension-model {
    width: 150%;
    height: 150%;
  }
}

@media only screen and (max-width: 720px) {
  .container {
    display: block;
    height: fit-content;
  }

  .rightcontainer {
    height: 50vh;
    padding-bottom: 3rem;
    width: 100%;
    align-items: flex-start;
  }

  .leftcontainer {
    width: 100%;
    padding: 6rem 1.4rem;
  }

  .head h2 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
  }

  .head>h1 {
    font-family: "Montserrat";
    font-size: 50px;
    line-height: 40px;
    color: #35abaf;
  }

  .details {
    width: 100%;
  }

  .details h1 {
    font-size: 16px;
  }

  .details p {
    font-size: 8px;
  }

  .third-Dimension-model {
    width: 150%;
    height: 150%;
  }
}