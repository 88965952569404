.main {
  background-image: url("../assets/png/bgImg2.png");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;

  .container {
    width: 100vw;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: scroll;
  }
}