.header {
  width: 100%;
  top: 0%;

  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 80px;
    position: relative;
    width: 100%;

    .logo {
      width: 8%;
      cursor: pointer;

      & img {
        width: 100%;
        height: 100%;
      }
    }

    .navMenu {
      height: 100% !important;
      
    }

    .searchbar > p {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .searchbar input {
      height: 100%;
      border-top-right-radius: 2rem;
      border-bottom-right-radius: 2rem;
      outline: none;
      border: none;
      background-color: transparent;
      color: white;
    }

    .searchbar {
      padding: 0 10px;
      height: 48px;
      width: 320px;
      border-radius: 2rem;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      cursor: pointer;
      background: rgba(5, 5, 5, 0.6);
      border: 1px solid rgb(107, 107, 107);
    }
    .avatar {
      padding: 0 10px;
      height: 48px;
      border-radius: 2rem;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      cursor: pointer;
    }

    .profileMain_container {
      display: flex;
      align-items: center;

      .DrawerContainer {
        display: none;
      }
    }
  }

  .headerLink {
    background-color: #35abaf;
    margin-top: -2px;
    padding: 1rem;
    color: white;
    border-radius: 0px 0px 4px 4px;
    width: auto;
    height: inherit !important;
    align-items: center !important;
    justify-content: center;
    text-align: center;
  }
}
.selectedMenu {
  color: red !important;
  background-color: red !important;
}
.searchResult {
  width: 30%;
  top: 14%;
  left: 60%;
  position: absolute;
  background-color: transparent;
  z-index: 200;
  padding: 1rem;
  background: rgb(5, 5, 5);
  border-radius: 1rem;
  border: 1px solid rgba(107, 107, 107, 0.591);

  .searchList{
    min-height: 200px;
    max-height: 500px;
    overflow-y: scroll;
  }

  .searchTxt {
    text-transform: lowercase;
    text-decoration: underline;
    margin: 10px 0;

    &:hover {
      cursor: pointer;
      font-size: calc(20px);
      transition-duration: 0.2s;
    }
  }
}

@media only screen and (max-width: 900px) {
  .searchbar,
  .navMenu {
    display: none !important;
  }

  .logo {
    width: 25% !important;
  }

  .container {
    .profileMain_container {
      .DrawerContainer {
        display: block !important ;
        margin: auto;
      }
    }
  }

  .listItem_text {
    color: black !important;
  }
}
