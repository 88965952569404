.main {
  background-color: rgb(23, 66, 67);
  width: 100%;
  padding: 30px 0;

  .subscribe {
    border-radius: 15px !important;
    background-color: rgba(27, 27, 27, 0.4);
    margin: 2rem auto;
    padding: 2rem;
  }
  // .emailField {
  //   border-radius: 4px;
  //   background: #c4c4c4;
  //   display: flex;
  //   align-items: center;
  //   width: 100%;
  //   text-decoration: #000;
  //   color: #000;
  // }
  .buttonField {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 28px;
    border-radius: 12px;
    // background: rgba(35, 35, 35, 0.3);
  }
  .subscribeButton {
    display: flex;
    width: 240px;
    height: 44px;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    background: #35abaf;
  }

  .logo {
    height: 80px;
    margin: 20px 0;
    width:100px;
  }
  .footer {
    display: flex;
    // width: 1440px;
    padding: 60px 160px 80px 80px;
    gap: 10px;
    justify-content: space-between !important;
    align-items: flex-start;
    // background: var(--gradient2-for-buttons, linear-gradient(0deg, rgba(53, 171, 175, 0.20) 0%, rgba(53, 171, 175, 0.20) 100%), linear-gradient(96deg, rgba(53, 171, 175, 0.26) 0.42%, rgba(5, 5, 5, 0.40) 100%));
  }

  @media only screen and (min-width: 300px) and (max-width: 576px) {
    .footer {
      padding: 0;
    }
  }
}
