.main{
    background-image: url("../../../assets/png/bgImg2.png");
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
}

.container {
    width: 100%;
    /* height: fit-content; */
    height: 100%;
    min-height: 100vh;
    color: white;
    display: flex;
    justify-content: center;
    font-family: 'DM Sans';
    font-style: normal;
}

.contained {
    width: 90%;
    margin: 8% auto 0;
    font-family: 'DM Sans';
    font-style: normal;
}
.contained h1{
    text-align: center;
    font-size: xx-large;
    color: #35ABAF;
}
.searchbar{
    width: 30%;
    height: 3rem;
    border-radius: 5px;
    display: flex;
    margin: auto;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    justify-content: center;
    border: 1px solid #35ABAF;
    background: #232323
}
.searchbar p {
    width: 15%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.searchbar input{
    width: 90%;
    height: 100%;
    border-top-right-radius: 2rem;
    border-bottom-right-radius: 2rem;
    outline: none;
    border: none;
    background-color: transparent;
    color: white;
}
.faqs{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
}
.faq{
    width: 20%;
    background: #666666;
    border: 1px solid #484848;
    border-radius: 4px;
    padding: 20px 32px 20px 32px;
    cursor: pointer;
     background-color: #484848;
  border: 1px solid #ddd;
  border-radius: 8px;
  width: 300px; /* Adjust card width for responsiveness */
  height: 250px; /* Fixed height with scrolling content */
  padding: 15px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  overflow: hidden;
    /* border: 1px solid red; */
}
.faq p::-webkit-scrollbar {
  width: 1px; /* Width of the scrollbar */
  background-color: gray;
}
.faq p::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1); /* Track color */
}

.faq p::-webkit-scrollbar-thumb {
  background-color: rgba(53, 171, 175, 0.7); /* Thumb color */
  border-radius: 10px; /* Rounded corners for the thumb */
  border: 2px solid transparent; /* Add some space around the thumb */
}

.faq p::-webkit-scrollbar-thumb:hover {
  background-color: rgba(53, 171, 175, 0.9); /* Darker on hover */
}

.faq span{
    font-weight: 700;
    font-size: 16px;
    color: #FCFCFC;
}
.faq p{
    padding: 20px 0;
    font-weight: 400;
    line-height: 150%;
    font-size: 12px;
    justify-content: flex-end;
     overflow-y: auto;
}

@media screen and (max-width: 768px) {
    .searchbar {
      width: 100%;
    }
    .faqs{
        flex-direction: column;
        align-items: center;
    }
    .faq{
        width: 100%;
        height: auto;
    }
  }