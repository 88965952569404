.CLB_container {
  font-family: "DM Sans";
  color: #FCFCFC;
}

.CLB_container h1 {
  text-align: center;
  color: white
}

.Clb_section {
  /* background-color: red; */
  display: grid;
  grid-template-columns: auto auto auto auto;
  gap: 40px;
  padding: 2rem 2rem;
}

.inner_CLB {
  cursor: pointer;
  width: 300px;
  display: flex;
  justify-content: center;
}

/* .Clb_section>div{
    border: 2px solid red;
} */
.btm_arr {
  display: flex;
  justify-content: center;
  cursor: pointer;
}

@media only screen and (max-width: 920px) {
  .Clb_section {
    grid-template-columns: auto auto !important;
    gap: 40px;
    padding: 2rem 1rem;
    justify-content: center;
    width: 100%;
  }

  .inner_CLB {
    height: 400px !important;
  }

  .Clb_section {}
}

@media only screen and (max-width: 720px) {
  .Clb_section {
    /* transform: scale(0.6); */
    grid-template-columns: auto !important;
    padding: 2rem 3rem !important;
  }
}