.main {
  background-image: url("../assets/png/bgImg2.png");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
 
}


// .imageContainer {
//   position: relative;
//   width: 100%;
//   height: 300px; // Set a fixed height
//   overflow: hidden; // Hide overflow to maintain aspect ratio

//   img {
//     position: absolute;
//     top: 50%;
//     left: 50%;
//     width: 100%; // Make image take full width
//     height: auto; // Maintain aspect ratio
//     transform: translate(-50%, -50%); // Center the image
//   }
// }

// img {
//   width: 100%; // Full width
//   height: 45%; // Full height of the container
//   object-fit: cover; // or 'contain' based on your needs
// }

.imageContainer {
  position: relative;
  width: 100%;
  min-height: 300px; /* Adjust the min-height to prevent overlapping */
  overflow: hidden; /* Hide overflow to maintain aspect ratio */

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%; /* Make image take full width */
    height: auto; /* Maintain aspect ratio */
    transform: translate(-50%, -50%); /* Center the image */
    object-fit: cover; /* Ensure the image covers the container properly */
  }
}

img {
  width: 100%; /* Full width */
  // height: 45%; /* Set a fixed height for images */
  // max-height: 300px;
  object-fit: cover; /* Cover the entire container */
}

