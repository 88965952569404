// create clb--------->
.main {
  background-image: url("../assets/png/bgImg2.png");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;

  .container {
    // width: 100vw;
    width: 100%;
    padding: 30px 0;
    margin: 0%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    .dropCLb {
      background-color: #484848;
      border-radius: 10px;
    }
  }
}

.MuiPickersArrowSwitcher-root {
  .MuiButtonBase-root {
    svg {
      fill: #484848;
    }
  }
}

.typeText {
  div {
    label {
      color: #ffffff !important;
    }
  }
}
