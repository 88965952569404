/* latest collection style */

.container {
  display: flex;
  padding: 12px;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  height: 128px;
  background: var(
    --Gradient,
    linear-gradient(
      96deg,
      rgba(53, 171, 175, 0.26) 0.42%,
      rgba(5, 5, 5, 0.4) 100%
    )
  );
}

.imageTextContainer {
  display: flex;
  width: 274px;
  align-items: flex-start;
  flex-shrink: 0;
  gap: 0;
  height: 100%;
  
 
}
.mainImage {
  flex: 1 0 0;
  border-radius: 8px;
  width: 100%;
  height: 100%;
  object-fit: cover; 
  width: 70%; 
  height: 100%; 
  object-fit: cover;
  aspect-ratio: 1 / 1;
  /* background: url(<path-to-image>), lightgray 50% / cover no-repeat, url(<path-to-image>), lightgray 50% / cover no-repeat, url(<path-to-image>), lightgray 50% / cover no-repeat, url(<path-to-image>), lightgray 50% / cover no-repeat; */
}

.imageBox {
  width:40%;
  height: 90px; 
  position: relative;
  overflow: hidden; 
  border-radius: 8px;
  flex-shrink: 0;
  
}

.textContainer {
  display: flex;
  flex-direction: column;

  max-width: 100%; 
  overflow: hidden; 
  height: 100%;
 
 
}

.circleImageBox {
  /* display: flex;
  align-items: center;
  position: relative; */
  width: 100%;
  cursor: pointer;
}

.circleImages {
  position: absolute;
  flex-shrink: 0;
  border-radius: 29px;
  width: 29px;
  height: 29px;
  max-width: 100%;
}

.textTitle {
  color: #fcfcfc;
  font-family: "DM Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 137.4%; 
  white-space: nowrap; 
  overflow: hidden; 
  text-overflow: ellipsis; 
}

.textSubTitle {
  color: #c4c4c4;
  font-family: "DM Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  white-space: nowrap; 
  overflow: hidden; 
  text-overflow: ellipsis; 
}

.textPrice {
  color: #c4c4c4;
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 137.4%; 
}
.nextButton {
  cursor: pointer;
  width: 36px;
  height: 36px;
  flex-shrink: 0;
}
