.cartList {
    overflow-y: auto;
    max-height: 500px;
    padding: 10px;
    width: 100%;
    scrollbar-width: thin; /* For Firefox */
    scrollbar-color: rgba(255, 255, 255, 0.3) transparent; /* Custom scrollbar color for Firefox */
    -ms-overflow-style: none; /* For Internet Explorer and Edge */

    /* For Webkit Browsers (Chrome, Safari, etc.) */
    &::-webkit-scrollbar {
        width: 1px; /* Extremely thin scrollbar */
    }
    &::-webkit-scrollbar-thumb {
        background-color: rgba(255, 255, 255, 0.3); /* Custom scrollbar thumb color */
        border-radius: 10px;
    }
    &::-webkit-scrollbar-track {
        background-color: transparent; /* Scrollbar background */
    }
}
/* Individual cart item */
.cartItem {
    background: #141414;
    border-radius: 4px;
    padding: 15px;
    margin-bottom: 15px;
    display: flex;
    flex-direction: row; /* Start with row for larger screens */
    align-items: flex-start;
    justify-content: space-between;
    gap: 10px;
    width: 100%;

    @media (max-width: 1024px) {
        flex-direction: column; /* Switch to column on medium screens */
        align-items: flex-start;
        padding: 12px;
    }

    @media (max-width: 768px) {
        flex-direction: column; /* Ensure stacking on small screens */
        align-items: flex-start;
        padding: 10px;
    }

    .title {
        font-weight: 700;
        font-size: 1rem;
        color: #FCFCFC;
        margin-bottom: 5px;
        white-space: normal;
        word-wrap: break-word;
        max-width: 100%;
        
    }

    .bodyText {
        color: #999999;
        font-size: 0.9rem;
        white-space: normal;
        word-wrap: break-word;
        max-width: 100%;
        margin-bottom: 10px; /* Add margin for better spacing */
    }

 .priceSection {
    display: flex;
    flex-direction: row; /* Keep items in a row */
    align-items: center; /* Align items vertically centered */
    width: 100%; /* Ensure it takes full width */
    justify-content: space-between; /* Add space between items if needed */

    .price {
        font-size: 1rem;
        color: #FCFCFC;
        margin-left: auto; /* Push the price to the right */
       
    }
}



    .crossButton {
        background-color: red;
        color: white;
        padding: 5px 10px;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        align-self: flex-end; /* Align cross button to the right on large screens */
        margin-top: 10px;

        @media (max-width: 768px) {
            align-self: flex-start; /* Move cross button below price on small screens */
            margin-top: 5px;
        }
    }
}

/* Ensure all cart item content stacks properly on medium screens */
@media (max-width: 1024px) {
    .cartItem {
        flex-direction: column;
        align-items: flex-start;
    }

    .title, .bodyText, .priceSection {
        max-width: 100%;
    }
}

/* Further adjust for mobile screens */
@media (max-width: 768px) {
    .cartItem {
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
    }

    .title, .bodyText, .priceSection {
        font-size: 0.9rem;
    }
}

/* Styling for Cart Total section */
.cartTotal {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;

    .totalText {
        font-weight: 700;
        font-size: 1.1rem;
        color: #FCFCFC;
    }

    .totalPrice {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        .price {
            font-size: 1rem;
            color: #FCFCFC;
        }
    }
}

@media (max-width: 768px) {
    .cartTotal {
        flex-direction: column;
        align-items: flex-start;

        .totalPrice {
            align-items: flex-start;
        }
    }
}

/* Button styling */
.continueButton {
    background-color: #1976d2;
    color: white;
    padding: 10px 20px;
    border-radius: 4px;
    text-align: center;
    margin-top: 20px;
    width: 100%;
    font-size: 1rem;
}

@media (max-width: 768px) {
    .continueButton {
        font-size: 0.9rem;
        padding: 8px 15px;
    }
}
// scroll item 

.cartList{
    overflow-y: scroll;
    height: 300px;
    max-height: 500px;

    .cartItem {
        background: #141414 !important;
        border-radius: 4px;
        padding: 10px;
        margin-bottom: 10px;
    
        .title {
            font-weight: 700;
            font-size: 16px;
            color: #FCFCFC;
        
        }
    
        .bodyText {
            color: #999999;
            font-size: 16px !important;
        }
    }
}

//2

















