.main {
  background-image: url("../assets/png/bgImg2.png");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;

  .coverImg {
    width: 100%;
    height: 300px;
  }

  .coverImg {
    width: 100%;
    height: 300px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .avtivOptBtn {
    color: #35abae !important;
    font-size: 18px;
  }

  .InAvtivOptBtn {
    color: #fcfcfc !important;
    font-size: 18px;
  }

  .avtivFollowSectionBtn {
    color: #35abae !important;
  }

  .InAvtivFollowSectionBtn {
    color: #ffffff !important;
    background-color: black;
  }
  .userImage{
    width: 200px;
    height: 200px;
    margin-top: -10%;
    border: 2px solid #35ABAF;
  }
}
